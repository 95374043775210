<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>基础设置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/advertising' }"
          >弹窗广告设置
        </el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="200px"
        size="mini"
      >
        <el-form-item label="图片：" label-position="left" prop="pic">
          <cover
            :uploadPath="form.pic"
            text="330*400px,顶部预留30px,支持PNG、JPG、GIF格式，小于5M"
            @success="uploadSuccess"
          ></cover>
        </el-form-item>

        <el-form-item
          label="小程序页面链接："
          label-position="left"
          prop="page"
        >
          <div class="flex">
            <el-input v-model="form.page" class="input" style="width: 220px" />
            <div class="padding-l-sm">链接案例《pages/index/index》</div>
          </div>
        </el-form-item>

        <el-form-item label="类型：" label-position="left" prop="type">
          <el-radio v-model="form.type" :label="1" border>仅一次</el-radio>
          <el-radio v-model="form.type" :label="2" border>时间段</el-radio>
        </el-form-item>

        <el-form-item label="状态：" label-position="left" prop="status">
          <el-radio v-model="form.status" :label="0" border>下架</el-radio>
          <el-radio v-model="form.status" :label="1" border>上架</el-radio>
        </el-form-item>

        <el-form-item
          v-if="form.type === 2"
          label="开始时间："
          label-position="left"
          prop="start_at"
        >
          <el-date-picker
            v-model="form.start_at"
            placeholder="选择开始时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          v-if="form.type === 2"
          label="结束时间："
          label-position="left"
          prop="end_at"
        >
          <el-date-picker
            v-model="form.end_at"
            placeholder="结束时间"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
            >保存
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            @click="$router.go(-1)"
            >取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Cover from "@/components/cover.vue";

export default {
  name: "add",
  components: { Cover },
  data() {
    return {
      loading: false,
      form: {
        type: 1,
        status: 1,
      },
      rules: {
        pic: [{ required: true, message: "请上传图片", trigger: "change" }],
        page: [{ required: true, message: "请输入路径", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        start_at: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        end_at: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
    };
  },
  methods: {
    ...mapActions("advertising", ["toastAdAdd"]),
    uploadSuccess(path) {
      this.form.pic = path;
    },
    async onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const { res_info } = await this.toastAdAdd(this.form);
            if (res_info !== "ok") return;
            this.$message.success("创建成功");
            this.$router.go(-1);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
}
</style>